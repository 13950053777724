import React from 'react'
import "./services.css"
import { BiCheck } from 'react-icons/bi'
import { RxCross2 } from 'react-icons/rx'

const Services = () => {
  return (
    <section id='services'>
      <h5>What I Offer</h5>
      <h2>Website Services</h2>

      <div className="container services__container">
        <article className='service'>
          <div className="service__head">
            <h3 className='tool' data-tip="Create a professional and mobile-friendly website using HTML, CSS, and JavaScript.">Basic</h3>
          </div>
          <ul className='service__list'>
            <li><BiCheck className='service__list-icon' />
              <p>Functional website</p>
            </li>
            <li><RxCross2 className='service__list-icon2' />
              <p>Opt-in form</p>
            </li>
            <li><RxCross2 className='service__list-icon2' />
              <p>Speed optimization</p>
            </li>
            <li><RxCross2 className='service__list-icon2' />
              <p>Hosting setup</p>
            </li>
            <li><BiCheck className='service__list-icon' />
              <p>Social media icons</p>
            </li>
            <li><BiCheck className='service__list-icon' />
              <p>1 page</p>
            </li>
            <li><BiCheck className='service__list-icon' />
              <p>1 Plugin/extension installation</p>
            </li>
            <li><BiCheck className='service__list-icon' />
              <p>1 product</p>
            </li>
            <li><RxCross2 className='service__list-icon2' />
              <p>0 Revision</p>
            </li>
            <li><BiCheck className='service__list-icon' />
              <p>Package - ₹8,675</p>
            </li>
          </ul>
        </article>
        {/* End of UI/UX */}
        <article className='service'>
          <div className="service__head">
            <h3 className='tool' data-tip="Implement interactive features such as sliders, accordions, or modals to engage users.">Standard</h3>
          </div>
          <ul className='service__list'>
            <li><BiCheck className='service__list-icon' />
              <p>Functional website</p>
            </li>
            <li><BiCheck className='service__list-icon' />
              <p>Opt-in form</p>
            </li>
            <li><RxCross2 className='service__list-icon2' />
              <p>Speed optimization</p>
            </li>
            <li><RxCross2 className='service__list-icon2' />
              <p>Hosting setup</p>
            </li>
            <li><BiCheck className='service__list-icon' />
              <p>Social media icons</p>
            </li>
            <li><BiCheck className='service__list-icon' />
              <p>2 pages</p>
            </li>
            <li><BiCheck className='service__list-icon' />
              <p>2 Plugins/extensions installation</p>
            </li>
            <li><BiCheck className='service__list-icon' />
              <p>1 product</p>
            </li>
            <li><BiCheck className='service__list-icon' />
              <p>1 Revision</p>
            </li>
            <li><BiCheck className='service__list-icon' />
              <p>Package - ₹13,012</p>
            </li>
          </ul>
        </article>
        {/* End of web dev */}
        <article className='service'>
          <div className="service__head">
            <h3 className='tool' data-tip="Develop dynamic web applications using advanced frameworks like React, Next.js and APIs.">Premium</h3>
          </div>
          <ul className='service__list'>
            <li><BiCheck className='service__list-icon' />
              <p>Functional website</p>
            </li>
            <li><BiCheck className='service__list-icon' />
              <p>Opt-in form</p>
            </li>
            <li><BiCheck className='service__list-icon' />
              <p>Speed optimization</p>
            </li>
            <li><BiCheck className='service__list-icon' />
              <p>Hosting setup</p>
            </li>
            <li><BiCheck className='service__list-icon' />
              <p>Social media icons</p>
            </li>
            <li><BiCheck className='service__list-icon' />
              <p>4 pages</p>
            </li>
            <li><BiCheck className='service__list-icon' />
              <p>4 Plugins/extensions installation</p>
            </li>
            <li><BiCheck className='service__list-icon' />
              <p>1 product</p>
            </li>
            <li><BiCheck className='service__list-icon' />
              <p>2 Revisions</p>
            </li>
            <li><BiCheck className='service__list-icon' />
              <p>Package - ₹21,687</p>
            </li>
          </ul>
        </article>
        {/* end of content creation */}
      </div>
    </section>
  )
}

export default Services