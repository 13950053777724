import React from 'react'
import "./portfolio.css"
import IMG1 from "../../assets/portfolio1.jpg";
import IMG2 from "../../assets/portfolio2.jpg";
import IMG3 from "../../assets/portfolio3.jpg";
import IMG4 from "../../assets/portfolio4.jpg";
import IMG5 from "../../assets/portfolio5.jpg";

const data = [
  {
    id: 1,
    image: IMG1,
    title: 'DALLE - AI Image Generation App Made Using OpenAi API',
    github: 'https://github.com/HarshMarolia/DALLE',
    demo: 'https://www.youtube.com/watch?v=G-Cr00UYokU'
  },
  {
    id: 2,
    image: IMG3,
    title: 'INVOICERPRO - Automated Invoice System',
    github: 'https://github.com/HarshMarolia/Automated-Invoice-System',
    demo: 'https://www.youtube.com/watch?v=G-Cr00UYokU'
  },
  {
    id: 3,
    image: IMG2,
    title: 'EDUCARE - One Stop Solution For Students',
    github: 'https://github.com/HarshMarolia/Educare',
    demo: 'https://www.youtube.com/watch?v=G-Cr00UYokU'
  },
  {
    id: 4,
    image: IMG4,
    title: 'PLAYLISTPRO - Youtube Playlist Downloader',
    github: 'https://github.com/HarshMarolia/Youtube-Playlist-Downloader',
    demo: 'https://www.youtube.com/watch?v=G-Cr00UYokU'
  },
  {
    id: 5,
    image: IMG5,
    title: 'Inventory Management System',
    github: 'https://github.com/HarshMarolia/Inventory-Management-System',
    demo: 'https://www.youtube.com/watch?v=G-Cr00UYokU'
  },
  // {
  //   id: 6,
  //   image: IMG6,
  //   title: 'Crypto Currency Dashbord & finanacial visualization',
  //   github: 'https://github.com',
  //   demo: 'https://www.youtube.com/watch?v=G-Cr00UYokU'
  // }
]

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {
          data.map(({ id, image, title, github, demo }) => {
            return (
              <article key={id} className='portfolio__item'>
                <div className="portfolio__item-image">
                  <img src={image} alt={title} />
                </div>
                <h3>{title}</h3>
                <div className="portfolio__item-cta">
                  <a href={github} className='btn' target='_blank'>Github</a>
                  {/* <a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a> */}
                </div>
              </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Portfolio