import React from 'react'
import "./testimonials.css"
import AVTR1 from "../../assets/avatar1.jpg"
import AVTR2 from "../../assets/avatar2.jpg"
import AVTR3 from "../../assets/avatar3.jpg"
import AVTR4 from "../../assets/avatar4.jpg"

// import Swiper core and required modules
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const data = [
  {
    avatar: AVTR1,
    name: 'Param Siddharth (SDE @ Travelopia)',
    review: `Harsh is a passionate and dedicated learner. He is confident; He knows his potential and doesn't stop once he commit himself to a new project or technology. Having been a close friend of him, I also happen to know that he is a kind-hearted friend and a great person to work with.`
  },
  {
    avatar: AVTR2,
    name: 'Updesh Dorwal (Senior Operations Manager @ Orange Business Services)',
    review: `I know Harsh when he was growing up as a kid. He was very focused and always ready to accept new challenges in life. Harsh is a disciplined and focused individual with a hunger to learn technology. I have seen some of his recent projects which showcase his skill and abilities as a software engineer. He is excellent in many front end development languages like CSS, HTML, JAVA etc. along with automation skills. He is working on sharpening his skills on AI & ML. I recommend him highly for any critical assignments. All the best Harsh...!! `
  },
  {
    avatar: AVTR3,
    name: 'Anant Kumar (Founder and CEO @ Bhoomi AI)',
    review: `I had the pleasure of working with Harsh on the creation and deployment of Bhoomi AI's company website. Harsh and his team did an outstanding job, from initial development to seamless deployment. Their technical expertise and dedication were evident throughout the project. He played a pivotal role in ensuring the website's success, and I have the utmost confidence in recommending him. I look forward to the opportunity to work with Harsh and his team again on future projects.`
  },
  {
    avatar: AVTR4,
    name: 'Esha Mahendra (Software Engineer @ BharatPe)',
    review: `Harsh is very well equipped with Technical knowledge. He has gained some extraordinary skills like Web development while in Graduation. He is quick learner and works dedicatedly. He is perfect for team level tasks and assignments.`
  },
  // {
  //   avatar: AVTR5,
  //   name: 'Manika Sharma',
  //   review: `The duration I've worked with Harsh I have seen him learn from his success and a lot more from his failures. He is quite consistent in the efforts he puts into his work. Moreover he is a fun loving person and enjoys every task given to him. Also he coordinates well with the team and have very well organized leadership qualities.`
  // },
]

const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h5>Review from clients & colleagues</h5>
      <h2>Testimonials</h2>
      <Swiper className="container testimonials__container"
        // install Swiper modules
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}>
        {
          data.map(({ avatar, name, review }, index) => {
            return (
              <SwiperSlide key={index} className='testimonial'>
                <div className="client__avatar">
                  <img src={avatar} alt="Avatar One" />
                </div>
                <h5 className='client__name'>{name}</h5>
                <small className='client__review'>{review}</small>
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </section>
  )
}

export default Testimonials